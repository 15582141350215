.SnackbarContent-root {
  max-height: 50vh;
  overflow: scroll;
  height: 100%;
}
.SnackbarItem-message {
  white-space: pre-wrap;
}
.SnackbarItem-action {
  margin-right: 32px !important;
}
.SnackbarItem-action > button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
